<template>
  <RoomLayout>
    <template #background-image>
      <img
        :data-src="require('@/assets/images/planet-ring.svg')"
        alt="planet"
      >
    </template>
    <template #content>
      <div class="flex flex-col w-full mt-5">
        <p class="geminis text-space-green text-4xl text-center pb-3">
          {{ $t('games.room_join.title') }}
        </p>
        <div class="flex sm:flex-wrap items-center md:flex-nowrap space-y-4 space-x-4 p-8 bg-white rounded-xl">
          <h2 class="w-1/2 font-raleway-extra-bold text-space-dark-blue text-3xl">
            {{ $t('games.room_account.account_existing') }}
          </h2>
          <PrimaryButton
            :classes="'w-1/2'"
            :bg-color="'bg-space-dark-blue'"
            :text="$t('games.room_account.login')"
            @click="goToConnectionPage"
          />
        </div>
        <div class="flex flex-col items-center space-y-6 p-8 bg-white rounded-xl mt-12">
          <h2 class="self-start font-raleway-extra-bold text-space-dark-blue text-3xl">
            {{ $t('games.room_account.join_as_guest') }}
          </h2>

          <span
            :class="(!errors || !errors.global ? 'hidden' : 'error-box text-space-darker-red w-full mt-3 mb-3')"
          >
            {{ errors && errors.global }}
          </span>
          <TextFormInput
            :placeholder="$t('games.room_account.placeholder')"
            :wrapper-classname="'bg-space-dark-blue'"
            :type="'text'"
            :value="pseudo"
            field="pseudo"
            :errors="errors"
            :container-classname="'w-full'"
            @input="pseudo = $event"
          />
          <PrimaryButton
            :classes="'w-2/3'"
            :bg-color="'bg-space-dark-blue'"
            :text="$t('games.room_account.join')"
            @click="join"
          />
        </div>
      </div>
    </template>
  </RoomLayout>
</template>

<script>
import RoomLayout from "@/layouts/Room/RoomLayout";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import FormService from "@/services/formService";
import TextFormInput from "@/components/inputs/TextFormInput.vue";
import {ROUTE_LOGIN} from "../../router";
import UserService from "../../services/userService";
import {UPDATE_ROOM_STATE} from "../../store/modules/userModule/actions";
import {isNull} from "../../utils/isNull";
import {store} from "../../store/store";
import {GAME_CONFIGURATION} from "../../constants/games/GameStatusConstants";

export default {
  name: "RoomAccount",
  components: {TextFormInput, PrimaryButton, RoomLayout},
  data: () => {
    return {
      pseudo: null,
      errors: {},
    };
  },
  mounted() {
    this.pseudo = this.$store.getters.getGuestPseudo;
  },
  methods: {
    async goToConnectionPage() {
      await this.$router.push({name: ROUTE_LOGIN.name, query: {
        pin: this.$route.query.pin,
        redirect: this.$route.query.redirect
      }
      });
    },

    async join() {
      const formErrors = {
        pseudo: FormService.checkNotEmpty(this.pseudo)
      };
      if (FormService.hasErrors(formErrors)) {
        this.errors = formErrors;
        return;
      }

      const user = this.$store.getters.getUser;
      if (!isNull(user.id) && user.pseudo === this.pseudo) {
        this.updateRoomState();

        await this.$router.push({
          name: this.$route.query.redirect, query: {
            pin: this.$route.query.pin,
          }
        });
        return;
      }

      try {
        await UserService.signUpGuest(this.pseudo);
        this.updateRoomState();
        await this.$router.push({
          name: this.$route.query.redirect,
          query: {
            pin: this.$route.query.pin,
          }
        });
      } catch (err) {
        this.$toast.error(this.$i18n.t('games.room_account.errors.pseudo_already_exists').toString());
      }
    },

    updateRoomState() {
      store.dispatch(UPDATE_ROOM_STATE, GAME_CONFIGURATION);
    }
  }
};
</script>
